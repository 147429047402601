<template>
  <ANZTEXT :data="data" :full-width="true"></ANZTEXT>
</template>

<script>
import ANZTEXT from '@/components/Anzcontrols/ANZTEXT';

export default {
  name: 'ANZTEXTFULL',

  components: {
    ANZTEXT
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>
